import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import { Field, type FieldProps } from 'formik'
import { type ReactNode } from 'react'

import { captureException } from '@services/exceptions/capture-exception'

interface FormCheckFieldProps {
  children: ReactNode
  name: string
}

const FormCheckField = ({ children, name }: FormCheckFieldProps) => {
  return (
    <div className='mb-5 flex items-center'>
      <span className='mr-3 text-sm font-medium text-black'>
        {children}
      </span>

      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const handleChange = (value: boolean) => {
            form.setFieldValue(name, value).catch(captureException)
          }

          return (
            <div className='flex items-center'>
              <span className='mr-3 text-sm font-medium text-gray-600'>
                No
              </span>

              <label className='relative flex cursor-pointer items-center' htmlFor={name}>
                <Switch
                  checked={field.value}
                  className={classNames(
                    field.value ? 'bg-primary' : 'bg-gray-200 hover:bg-gray-300',
                    'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-all ease-in-out duration-500 focus:outline-none'
                  )}
                  onChange={handleChange}
                >
                  <span className='sr-only'>Use setting</span>

                  <span
                    className={classNames(
                      field.value ? 'translate-x-4' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-4 w-4 transform bg-white rounded-full shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  >
                    <span
                      aria-hidden='true'
                      className={classNames(
                        field.value ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                    >
                      <svg className='size-3 text-gray-400' fill='none' viewBox='0 0 12 12'>
                        <path
                          d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                          stroke='currentColor'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                        />
                      </svg>
                    </span>

                    <span
                      aria-hidden='true'
                      className={classNames(
                        field.value ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                    >
                      <svg className='size-3 text-primary' fill='currentColor' viewBox='0 0 12 12'>
                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                      </svg>
                    </span>
                  </span>
                </Switch>
              </label>

              <span className='ml-3 text-sm font-medium text-gray-600'>
                Yes
              </span>
            </div>
          )
        }}
      </Field>
    </div>
  )
}

export default FormCheckField
