import type { UseMutationResult } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import ButtonLoader from '@components/button-loader/button-loader'
import FormImageField from '@components/form-image-field'
import type { ApiReponseError } from '@interfaces/api'
import { useMe } from '@services/api/auth/use-me'
import usePatchMe from '@services/api/auth/use-patch-me'
import { useUploadImage } from '@services/api/use-upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { handleImageViolations } from '@services/tools/violations'

interface LogoFormProps {
  afterSubmit: () => void
  editMode?: boolean
}
const LogoForm = ({ afterSubmit, editMode = false }: LogoFormProps) => {
  const navigate = useNavigate()
  const { data: me } = useMe()
  const { mutateAsync: patchMe } = usePatchMe()
  const { mutateAsync: uploadImage } = useUploadImage()

  const [errorMsg, setErrorMsg] = useState('')
  const [initialValues, setInitialValues] = useState({
    logo: me?.logo ?? undefined
  })

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      logo: me?.logo ?? undefined
    })
  }, [me])

  const onSubmit = async (
    values,
    { setSubmitting }
  ) => {
    let errorMessage = ''

    setSubmitting(true)

    try {
      if (values.logo) {
        if ('@id' in values.logo) {
          values.logo = values.logo['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values.logo)
          try {
            values.logo = await uploadImage(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrorMsg(errorMessage)
          }
        }

        await patchMe({
          ...values
        })
      }
      setSubmitting(false)

      afterSubmit()
    } catch (err) {
      captureException(err as Error)

      setErrorMsg('An error occured')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form className=''>

            <div className='mb-10 flex flex-col items-center space-y-4'>
              <FormImageField
                backgroundColor={'bg-gray-300'}
                label='Add your logotype:'
                name={'logo'}
                previewCover={false}
                setFieldValue={setFieldValue}
                value={values.logo}
              />

              <div className='text-xs'>Jpg or png (max size 5mo)</div>

              {!editMode && (
                <div className='text-sm'>You can upload your logotype later.</div>
              )}
            </div>

            <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

            <div className='flex space-x-4'>
              {editMode
                ? (
                  <button
                    className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting ? <ButtonLoader /> : <>SAVE</>}
                  </button>
                )
                : (
                  <>
                    <button
                      className='flex w-full justify-center rounded-full border-0 bg-black py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                      onClick={() => {
                        navigate(-1)
                      }}
                      type='button'
                    >
                      PREVIOUS
                    </button>

                    <button
                      className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
                      disabled={isSubmitting}
                      type='submit'
                    >
                      {isSubmitting ? <ButtonLoader /> : <>START NOW</>}
                    </button>
                  </>
                )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LogoForm
