import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import slugify from 'slugify'

import ButtonLoader from '@components/button-loader/button-loader'
import { type CodeExport } from '@interfaces/api/code-export'
import { type CodeExportListInterfaces } from '@pages/codes/code-bulk-details/code-bulk-exports/components/code-export-list'
import useGetCodeExportCsv from '@services/api/use-get-code-export-csv'
import useGetCodeExports from '@services/api/use-get-code-exports'

const CodeExportList = ({ experience }: CodeExportListInterfaces) => {
  const [codeExportToDownload, setCodeExportToDownload] = useState<CodeExport>()
  const [triggerDownload, setTriggerDownload] = useState(false)

  const {
    data: {
      'hydra:member': codeExports = []
    }
    = {}
  } = useGetCodeExports({ parameters: { 'order[createdAt]': 'desc' }, uid: experience.uid ?? '' })

  const { data: csvData, isFetching, isSuccess: isCsvSuccess } = useGetCodeExportCsv(codeExportToDownload?.uid ?? '', triggerDownload)

  useEffect(() => {
    if (isCsvSuccess && csvData && codeExportToDownload) {
      const totalCount = codeExportToDownload.codeCount
      const fileName = `codes_${slugify(experience.name, { lower: true })}_${totalCount}.csv`

      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      setTriggerDownload(false)
    }
  }, [csvData, isCsvSuccess, codeExportToDownload])

  const onDownloadClick = (codeExport: CodeExport) => {
    setCodeExportToDownload(codeExport)
    setTriggerDownload(true)
  }

  return (
    <div className='mt-5'>
      <div className='mb-4 text-lg font-bold'>
        {'Exports history'}
      </div>

      <table className='min-w-full divide-y divide-gray-50'>
        <thead className='bg-gray-30'>
          <tr className='px-3 py-3.5 text-left text-xs font-bold uppercase text-black'>
            <th className={'p-0 px-1 text-left'}>
              <div className='flex items-start'>
                <div className={'flex items-center justify-between gap-2 whitespace-nowrap p-3'}>
                  {'Created at'}
                </div>
              </div>
            </th>

            <th className={'p-0 px-1 text-left'}>
              <div className='flex items-start'>
                <div className={'flex items-center justify-between gap-2 whitespace-nowrap p-3'}>
                  {'Codes'}
                </div>
              </div>
            </th>

            <th className={'p-0 px-1 text-left'}>
              <div className='flex items-start'>
                <div className={'flex items-center justify-between gap-2 whitespace-nowrap p-3'}>
                  {'Download'}
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody className='divide-y divide-gray-50'>
          {codeExports.map((codeExport, index) => {
            return (
              <tr className='group cursor-pointer bg-white text-sm hover:bg-gray-30' key={`${codeExport.uid}-${index}`}>
                <td className='px-3 py-2'>{moment(codeExport.createdAt).format('DD/MM/YYYY')}</td>

                <td className='px-3 py-2 font-medium'>{codeExport.codeCount}</td>

                <td className='px-3 py-2'>
                  <button
                    className='flex w-24 items-center justify-center space-x-1 px-2 py-1'
                    onClick={() => {
                      onDownloadClick(codeExport)
                    }}
                    type='button'
                  >
                    {isFetching && codeExport.uid === codeExportToDownload?.uid
                      ? (
                        <ButtonLoader dark />
                      )
                      : (
                        <ArrowDownOnSquareIcon className='size-5' />
                      )}
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CodeExportList
