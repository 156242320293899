import { MinusIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import type React from 'react'

import { type FormPlusMinusFieldInterfaces } from '@components/form-plus-minus-field'
import { captureException } from '@services/exceptions/capture-exception'

const FormPlusMinusField = ({ disabledMinus = false, label, name, onChange }: FormPlusMinusFieldInterfaces) => {
  const [field, , helpers] = useField(name)

  const handleDecrease = () => {
    const newValue = Math.max(0, field.value - 1)
    helpers.setValue(newValue).catch(captureException)
  }

  const handleIncrease = () => {
    const newValue = field.value + 1
    helpers.setValue(newValue).catch(captureException)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    if (!isNaN(newValue)) {
      helpers.setValue(newValue).catch(captureException)
    }
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <div className='flex flex-col'>
      <label className='text-sm font-bold' htmlFor={name}>
        {label}
      </label>

      <div className='mt-2 flex items-center space-x-2'>
        <button
          className={classNames(
            'flex size-6 items-center justify-center rounded-full',
            {
              'bg-primary': !disabledMinus,
              'bg-slate-400': disabledMinus
            }
          )}
          disabled={disabledMinus}
          onClick={handleDecrease}
          type='button'
        >
          <MinusIcon className='size-4 text-white' />
        </button>

        <Field
          className='w-20 rounded-md border border-gray-300 py-1 text-center text-sm'
          name={name}
          onChange={handleInputChange}
          type='text'
          value={field.value}
        />

        <button
          className='flex size-6 items-center justify-center rounded-full bg-primary'
          onClick={handleIncrease}
          type='button'
        >
          <PlusIcon className='size-4 text-white' />
        </button>
      </div>

      <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name={name} />
    </div>
  )
}

export default FormPlusMinusField
