import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'

import ButtonTooltip from '@components/button-tooltip/button-tooltip'
import { type CodeGroupListEntryInterfaces } from '@pages/codes/code-bulk-details/code-bulk-groups/code-group-list-entry'
import useGetCodeGroup from '@services/api/use-get-code-group'
import { captureException } from '@services/exceptions/capture-exception'

const CodeGroupListEntry = ({ codeGroup, onEditClick, onEntryClick }: CodeGroupListEntryInterfaces) => {
  const [enabled, setEnabled] = useState(false)
  const { data: codeGroupDetails } = useGetCodeGroup(codeGroup?.uid ?? '', enabled)

  const handleRowClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onEntryClick(codeGroup)
  }

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onEditClick(codeGroup)
  }

  const handleCopyCodes = (e: React.MouseEvent) => {
    e.stopPropagation()
    setEnabled(true)
  }

  useEffect(() => {
    if (codeGroupDetails && enabled) {
      const codesText = codeGroupDetails.codes.join('\n')
      navigator.clipboard.writeText(codesText)
        .then(() => {
          setEnabled(false) // Reset enabled to false after copying
        })
        .catch(captureException)
    }
  }, [codeGroupDetails, enabled])

  return (
    <tr
      className={classNames(
        'group cursor-pointer text-sm bg-white hover:bg-gray-30'
      )}
      key={codeGroup.uid}
      onClick={handleRowClick}
    >
      <td className='px-3 py-2 font-medium'>{codeGroup.orderNumber}</td>

      <td className='px-3 py-2 font-medium'>{codeGroup.comment}</td>

      <td className='px-3 py-2 font-medium'>
        <span className={classNames('rounded-lg border px-2 py-1 border-gray-300 bg-gray-200')}>
          {codeGroup.codeCount}
        </span>
      </td>

      <td className='px-3 py-2 font-medium'>
        {codeGroup.withTickets
          ? (
            <span className={classNames('rounded-lg border px-2 py-1', {
              'bg-green-200 border-green-200': codeGroup.fileCount > 0,
              'bg-red-200 border-red-300': codeGroup.fileCount <= 0
            })}
            >
              {codeGroup.fileCount}
            </span>
          )
          : (
            <span>
              {'No tickets'}
            </span>
          )}
      </td>

      <td className='px-3 py-2'>
        {codeGroup.createdAt ? moment(codeGroup.createdAt).format('DD.MM.YYYY HH:mm') : '-'}
      </td>

      <td className='px-3 py-2'>
        <div className='flex items-center space-x-1'>
          <button
            className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
            onClick={handleEditClick}
            type='button'
          >
            <PencilSquareIcon className='size-4' />
          </button>

          <ButtonTooltip handleCopyClick={handleCopyCodes} withBorder />

          <button
            className='rounded-md border border-gray-200 p-1 text-black hover:bg-black hover:text-white'
            onClick={handleRowClick}
            type='button'
          >
            <EyeIcon className='size-4' />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default CodeGroupListEntry
