import { ArrowDownOnSquareIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useParams } from 'react-router'

import Button from '@components/button/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { CodeBulkExports } from '@pages/codes/code-bulk-details/code-bulk-exports'
import { CodeBulkGroups } from '@pages/codes/code-bulk-details/code-bulk-groups'
import useGetCodeBulkExperience from '@services/api/use-get-code-bulks-experience'

const CodeBulkDetails = () => {
  const { uid } = useParams()
  const [openGroupForm, setOpenGroupForm] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const { data: codeBulkExperience } = useGetCodeBulkExperience(uid ?? '')

  const handleCreateGroupClick = () => {
    setOpenGroupForm(true)
  }

  const handleDownloadCsvClick = () => {
    setOpenExportModal(true)
  }

  return (
    <Layout wide>
      <Guard errorPage={false}>
        <div className='mx-auto max-w-7xl px-2 pb-20 sm:px-6 lg:px-8'>
          {codeBulkExperience && (
            <div className='mt-10 grid grid-cols-3 items-center'>
              <div className='flex items-start'>
                <Button href='/codes' icon={ChevronLeftIcon} iconRight={false} size={'small'} />
              </div>

              <div className='text-center'>
                <h2 className='text-2xl font-bold'>
                  {codeBulkExperience?.experience.name}
                </h2>

                <p className='my-2 text-xs uppercase'>{`${codeBulkExperience?.totalCount - codeBulkExperience?.remainingUnsentCount} codes sent`}</p>

                <p className='my-2 text-xs font-bold uppercase text-red-500'>{`${codeBulkExperience?.remainingUnsentCount} codes available`}</p>
              </div>

              <div className='flex justify-end space-x-2'>
                <Button icon={ArrowDownOnSquareIcon} onClick={handleDownloadCsvClick} size='medium' style='black'>
                  Download CSV
                </Button>

                {codeBulkExperience?.remainingUnsentCount <= 0
                  ? (
                    <Button href='/purchase' size='medium'>
                      {'Buy new codes'}
                    </Button>
                  )
                  : (
                    <Button onClick={handleCreateGroupClick} size='medium'>
                      {'Create booking'}
                    </Button>
                  )}

              </div>
            </div>
          )}

          {codeBulkExperience && (
            <CodeBulkGroups
              codeBulkExperience={codeBulkExperience}
              openCreateModal={openGroupForm}
              setOpenCreateModal={setOpenGroupForm}
            />
          )}

          {codeBulkExperience && (
            <CodeBulkExports
              codeBulkExperience={codeBulkExperience}
              open={openExportModal}
              setOpen={setOpenExportModal}
            />
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default CodeBulkDetails
