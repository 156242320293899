import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment } from 'react'

import { type ModalProps } from '@components/modal/modal.interfaces'

const Modal = ({ center = false, children, onClose, open, setOpen, size = 'normal', title }: ModalProps) => {
  const handleClose = (boolean) => {
    setOpen(boolean)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div aria-hidden='true' className='fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity' onClick={handleClose} />
        </Transition.Child>

        <div className='fixed inset-0 z-10 max-h-full overflow-y-auto'>
          <div className={`flex h-full min-h-full justify-center text-center sm:p-0 ${center ? 'items-center' : ' items-start'}`}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={classNames(
                'rounded-3xl text-left shadow-xl relative max-h-full overflow-hidden bg-white transition-all', {
                  '': size === 'xlarge',
                  'lg:max-w-[40rem]': size === 'medium',
                  'lg:max-w-lg sm:max-w-sm': size === 'normal',
                  'min-w-[40%]': size === 'large'
                }
              )}
              >
                <div className='relative flex items-center justify-between px-4 py-2.5'>
                  <div className='size-10' />

                  <div className='line-clamp-1 flex items-center justify-center gap-2 text-2xl font-bold text-gray-700'>
                    {title && title}
                  </div>

                  <div className=''>
                    <button
                      className={'flex items-end rounded-full bg-gray-400 p-1 text-white hover:bg-black'}
                      onClick={() => {
                        handleClose(false)
                      }}
                    >
                      <XMarkIcon className='size-5' />
                    </button>
                  </div>
                </div>

                <div
                  className={'relative max-h-[90vh] overflow-auto px-14 pb-10'}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
