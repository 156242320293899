import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformListResponse } from '@interfaces/api'
import { type CodeBulkExperience } from '@interfaces/api/code-bulk-experiences'
import useAxios from '@services/api/axios'

interface ResourceListParameters {
  parameters?: Record<string, boolean | number | string> | URLSearchParams
}

const useGetCodeBulksExperiences = <T extends CodeBulkExperience>({
  parameters
}: ResourceListParameters): UseQueryResult<ApiPlatformListResponse<T>> => {
  const { get } = useAxios()

  const queryKey = ['partner-realm/code-bulks', parameters]
  const queryFn = async () => {
    return await get('api/partner-realm/code-bulk-experiences', parameters)
  }
  const staleTime = 5 * 60 * 1000

  return useQuery({
    queryFn,
    queryKey,
    staleTime
  })
}

export default useGetCodeBulksExperiences
