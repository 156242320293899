import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiPlatformListResponse } from '@interfaces/api'
import { type CodeGroup } from '@interfaces/api/code-group'
import useAxios from '@services/api/axios'

interface ResourceListParameters {
  parameters?: Record<string, boolean | number | string> | URLSearchParams
  uid: string
}

const useGetCodeExperienceCodes = <T extends CodeGroup>({
  parameters,
  uid
}: ResourceListParameters): UseQueryResult<ApiPlatformListResponse<T>> => {
  const { get } = useAxios()

  const queryKey = ['partner-realm/code-bulks/groups', uid]
  const queryFn = async () => {
    return await get(`api/partner-realm/experiences/${uid}/code-groups`, parameters)
  }
  const staleTime = 5 * 60 * 1000

  return useQuery({
    queryFn,
    queryKey,
    staleTime
  })
}

export default useGetCodeExperienceCodes
