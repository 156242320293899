import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

interface CreateCodeExportParameters {
  codeCount: number
  experience: string
}

const useCreateCodeExport = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreateCodeExportParameters) => {
      return await post<never, never>('api/partner-realm/code-exports', data as never)
    }
  })
}

export default useCreateCodeExport
