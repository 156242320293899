import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SettingsMenu from '@pages/settings/settings-menu'
import SlideshowForm from '@pages/settings/settings-slideshow/slideshow-form/slideshow-form'
import SlideshowItems from '@pages/settings/settings-slideshow/slideshow-items/slideshow-items'
import { useFeatures } from '@services/features'

const SettingsSlideshow = () => {
  const { hasSlideshowFeature, isReady } = useFeatures()

  const navigate = useNavigate()

  useEffect(() => {
    if (!hasSlideshowFeature && isReady) {
      navigate('/settings')
    }
  }, [isReady, hasSlideshowFeature])

  return (
    <Layout>
      <Guard>
        <div className='mx-auto max-w-2xl px-2 pb-20 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <h2 className='text-3xl font-bold'>Settings</h2>

            <p className='my-2' />
          </div>

          <div className='my-10'>
            <SettingsMenu />
          </div>

          <div className='mt-10'>
            <h3 className='my-5 text-2xl font-bold'>Profil</h3>

            <SlideshowForm />
          </div>

          <div className='mt-10'>
            <h3 className='mt-5 text-2xl font-bold'>
              Content showcase
            </h3>

            <SlideshowItems />
          </div>

        </div>
      </Guard>
    </Layout>
  )
}

export default SettingsSlideshow
