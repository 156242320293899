import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import Button from '@components/button/button'
import { FormPlusMinusField } from '@components/form-plus-minus-field'
import { type CodeExportFormInterfaces } from '@pages/codes/code-bulk-details/code-bulk-exports/components/code-export-form'
import useCreateCodeExport from '@services/api/use-create-code-export'
import { captureException } from '@services/exceptions/capture-exception'

const CodeExportForm = ({ codeBulkExperience }: CodeExportFormInterfaces) => {
  const { mutateAsync: createCodeExport } = useCreateCodeExport()

  const queryClient = useQueryClient()

  const onSubmit = async (values, { resetForm, setErrors, setSubmitting }) => {
    setSubmitting(true)

    await createCodeExport({
      codeCount: values.codeCount,
      experience: codeBulkExperience?.experience['@id']
    })

    setSubmitting(false)
    resetForm()
    queryClient.refetchQueries({
      exact: true,
      queryKey: ['partner-realm/experiences/code-exports', codeBulkExperience?.experience.uid]
    }).catch(captureException)

    queryClient.refetchQueries({
      queryKey: ['code-bulk-experience', codeBulkExperience.uid]
    }).catch(captureException)
  }

  const FormSchema = Yup.object({
    codeCount: Yup.number().required('Required')
  })

  return (
    <div className=''>
      <div className='mb-4 text-lg font-bold'>
        {'Create an export'}
      </div>

      <Formik
        enableReinitialize
        initialValues={{ codeCount: 0 }}
        onSubmit={onSubmit}
        validationSchema={FormSchema}
      >
        {({ isSubmitting, isValid, setFieldValue, values }) => {
          return (
            <Form className='w-full'>
              <div className='flex items-center justify-between'>
                <FormPlusMinusField label={'Number of codes to export'} name={'codeCount'} />

                <Button
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {'Create export'}
                </Button>
              </div>

              <div className='mt-4 rounded-md bg-yellow-50 p-4'>
                <div className='flex'>
                  <div className='shrink-0'>
                    <ExclamationTriangleIcon aria-hidden='true' className='size-5 text-yellow-400' />
                  </div>

                  <div className='ml-3'>
                    <div className='text-sm text-yellow-700'>
                      <p>
                        {'Warning: When you export codes, they will be marked as sent and will no longer be available when creating a booking.'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CodeExportForm
