import ButtonTooltip from '@components/button-tooltip/button-tooltip'
import Modal from '@components/modal/modal'
import { type CodeBulkGroupViewModalInterfaces } from '@pages/codes/code-bulk-details/code-bulk-group-view-modal'
import useGetCodeGroup from '@services/api/use-get-code-group'
import { captureException } from '@services/exceptions/capture-exception'

const CodeBulkGroupViewModal = ({ codeGroup, open, setOpen }: CodeBulkGroupViewModalInterfaces) => {
  const { data: codeGroupDetails } = useGetCodeGroup(codeGroup?.uid ?? '', open)

  const onCopyClick = async (code: string) => {
    await navigator.clipboard.writeText(code)
  }

  const onCopyAllCodes = async () => {
    if (codeGroupDetails) {
      const codesText = codeGroupDetails.codes.join('\n')
      await navigator.clipboard.writeText(codesText)
    }
  }

  const onCopyOrderNumber = async (orderNumber: string) => {
    await navigator.clipboard.writeText(orderNumber)
  }

  return (
    <Modal center open={open} setOpen={setOpen} size='large' title={codeGroupDetails?.comment}>
      {codeGroupDetails && (
        <div className='flex flex-col items-center space-y-4'>

          <div className='mt-8 flex w-full items-center space-x-1'>
            <div className='font-bold'>Order Number :</div>

            <ButtonTooltip handleCopyClick={async () => {
              await onCopyOrderNumber(codeGroupDetails.orderNumber)
            }}
            >
              <span>{codeGroupDetails.orderNumber}</span>
            </ButtonTooltip>
          </div>

          <div className='mt-8 flex w-full items-center space-x-1'>
            <div className='font-bold'>Comment :</div>

            <span>{codeGroupDetails.comment}</span>
          </div>

          <div className='flex w-full flex-col space-y-2'>
            <div className='flex items-center space-x-2 font-bold'>
              <span>Codes</span>

              <ButtonTooltip handleCopyClick={onCopyAllCodes} tooltipText={'Copy all codes'} withBorder />
            </div>

            <ul className='flex space-x-2'>
              {codeGroupDetails.codes?.map((code, index) => (
                <li
                  key={`code-${index}`}
                >
                  <ButtonTooltip
                    handleCopyClick={async (e) => {
                      e.stopPropagation()
                      await onCopyClick(code).catch(captureException)
                    }} withBorder
                  >
                    <span className='px-1'>{code}</span>
                  </ButtonTooltip>
                </li>
              ))}
            </ul>
          </div>

          {codeGroupDetails.withTickets && (

            <div className='w-full space-y-2'>
              <div className='font-bold'>Tickets</div>

              {codeGroupDetails.files?.length
                ? (
                  <ul className='flex flex-col space-y-1'>
                    {codeGroupDetails.files?.map((file, index) => (
                      <li key={`file-${index}`}>
                        <a className='hover:underline' href={file.url} target='_blank'>
                          {file.originalName}
                        </a>
                      </li>
                    ))}
                  </ul>
                )
                : (
                  <div className='text-sm'>
                    {'There is no tickets for this group'}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}

export default CodeBulkGroupViewModal
