import { DocumentIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import BookingFilesField from '@components/booking-form/booking-files-field/booking-files-field'
import ButtonLoader from '@components/button-loader/button-loader'
import Modal from '@components/modal/modal'
import { type Booking, BookingState } from '@interfaces/api/booking'
import useGetBooking from '@services/api/use-get-booking'
import usePatchBookingFiles from '@services/api/use-patch-booking-files'
import usePatchPrepareBooking from '@services/api/use-patch-prepare-booking'
import { useUploadFile } from '@services/api/use-upload-file'
import { captureException } from '@services/exceptions/capture-exception'

interface BookingModalFilesProps {
  booking: Booking
  onSendClick: () => void
  open: boolean
  setOpen: (open: boolean) => void
}

const BookingModalFiles = ({ booking, onSendClick, open, setOpen }: BookingModalFilesProps) => {
  const { mutateAsync: uploadFile } = useUploadFile()
  const { mutateAsync: patchBooking } = usePatchBookingFiles()
  const { isPending, mutateAsync: prepareBooking } = usePatchPrepareBooking()
  const { data: bookingDetails, refetch } = useGetBooking(booking.uid, open)
  const [filesToUpload, setFilesToUpload] = useState<number>(0)
  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true)
    const uploadedFileIds: string[] = []

    setFilesToUpload(values.files.length)

    for (const file of values.files) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const uploadedId = await uploadFile(formData)
        uploadedFileIds.push(uploadedId)
      } catch (e) {
        // HANDLE ERRORS
        break // Stop processing further if any error occurs
      }
    }

    const existingFileIds = bookingDetails?.files ? bookingDetails.files.map(file => file['@id']) : []
    const mergedFileIds = [...existingFileIds, ...uploadedFileIds]

    patchBooking({ data: { files: mergedFileIds }, uid: booking.uid }).then(() => {
      setSubmitting(false)
      resetForm()
      setFilesToUpload(0)
      refetch().catch(captureException)
    }).catch(captureException)
  }

  const onNextClick = () => {
    prepareBooking({ data: {}, uid: booking.uid }).then(() => {
      refetch().catch(captureException)
    }).catch(captureException)
  }

  return (

    <Modal center open={open} setOpen={setOpen} title={bookingDetails?.files?.length ? 'Send tickets' : 'Upload Tickets'}>
      <div className='flex flex-col items-center'>
        <p className='mt-2'>Drag and drop your tickets here</p>

        <Formik
          enableReinitialize
          initialValues={{
            files: []
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form className=''>
                {(bookingDetails?.files?.length || filesToUpload > 0) && (

                  <ul className='my-4 space-y-1 border-y border-gray-200 py-3'>
                    {bookingDetails?.files?.map((file) => (
                      <li>
                        <a className='flex items-center justify-between space-x-2 rounded-lg px-2 hover:bg-gray-50'
                          href={file.url} target='_blank'
                        >
                          <span className='flex items-center space-x-2'>
                            <DocumentIcon className='size-4' />

                            <span>{file.originalName}</span>
                          </span>

                          <MagnifyingGlassIcon className='size-4' />
                        </a>

                      </li>
                    ))}

                    {isSubmitting && Array.from({ length: filesToUpload }).map((_, index) => (
                      <li key={index}>
                        <div className='flex items-center justify-between space-x-2 rounded-lg bg-gray-50 px-2'>
                          <span className='flex items-center space-x-2'>
                            <span className='h-5 w-full bg-gray-50' />
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>

                )}

                <BookingFilesField accept={'.pdf,.jpg,.jpeg,.png,.gif'} hasFiles={!!bookingDetails?.files?.length}
                  isSubmitting={isSubmitting} name={'files'} setFieldValue={setFieldValue}
                />

                {bookingDetails?.state === BookingState.ready &&
                  (

                    <div className='mt-5 flex flex-col space-y-2'>
                      <button
                        className='flex justify-center rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
                        onClick={onSendClick}
                        type='button'
                      >
                        Send
                      </button>

                      <button
                        className='flex justify-center rounded-full border-0 bg-black px-20 py-2 uppercase text-white'
                        onClick={() => {
                          setOpen(false)
                        }}
                        type='button'
                      >
                        Send later
                      </button>
                    </div>

                  )}

                {bookingDetails?.state === BookingState.pending && (
                  <div className='mt-5 flex flex-col space-y-2'>
                    <button
                      className='flex items-center justify-center space-x-2 rounded-full border-0 bg-primary px-20 py-2 uppercase text-white'
                      onClick={onNextClick}
                      type='button'
                    >
                      <span>Next</span>

                      {isPending && <ButtonLoader />}
                    </button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default BookingModalFiles
