/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline'
import type { UseMutationResult } from '@tanstack/react-query'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import BookingFilesField from '@components/booking-form/booking-files-field/booking-files-field'
import Button from '@components/button/button'
import FormCheckField from '@components/form-check-field/form-check-field'
import { FormPlusMinusField } from '@components/form-plus-minus-field'
import FormTextField from '@components/form-text-field/form-text-field'
import Modal from '@components/modal/modal'
import type { ApiReponseError } from '@interfaces/api'
import { type CodeGroupFile } from '@interfaces/api/code-group'
import { type CodeBulkGroupModalInterfaces } from '@pages/codes/code-bulk-details/code-bulk-group-modal'
import {
  type CodeBulkFormValues
} from '@pages/codes/code-bulk-details/code-bulk-group-modal/code-bulk-group-modal.interfaces'
import useCreateCodeGroup from '@services/api/use-create-code-group'
import useGetCodeGroup from '@services/api/use-get-code-group'
import usePatchCodeGroup from '@services/api/use-patch-code-group'
import { useUploadFile } from '@services/api/use-upload-file'
import { captureException } from '@services/exceptions/capture-exception'

const CodeBulkGroupModal = ({ codeGroup, experience, onSuccess, open, setOpen }: CodeBulkGroupModalInterfaces) => {
  const { t } = useTranslation('errors')
  const [filesToUpload, setFilesToUpload] = useState<number>(0)
  const { data: codeGroupDetails, refetch } = useGetCodeGroup(codeGroup?.uid ?? '', open && !!codeGroup)
  const { mutateAsync: patchCodeGroup } = usePatchCodeGroup()
  const { mutateAsync: createCodeGroup } = useCreateCodeGroup()
  const { mutateAsync: uploadFile } = useUploadFile()

  const defaultInitialValues = {
    codeCount: 1,
    comment: '',
    files: [],
    orderNumber: '',
    withTickets: false
  }
  const [initialValues, setInitialValues] = useState<CodeBulkFormValues>(defaultInitialValues)

  useEffect(() => {
    if (codeGroupDetails) {
      setInitialValues({
        codeCount: codeGroupDetails.codeCount,
        comment: codeGroupDetails.comment,
        files: [],
        orderNumber: codeGroupDetails.orderNumber,
        withTickets: codeGroupDetails.withTickets
      })
    }
  }, [codeGroupDetails])

  const onSubmit = async (values: CodeBulkFormValues, { resetForm, setErrors, setSubmitting }) => {
    setSubmitting(true)
    const uploadedFileIds: string[] = []

    setFilesToUpload(values.files.length)

    for (const file of values.files) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const uploadedId = await uploadFile(formData)
        uploadedFileIds.push(uploadedId)
      } catch (e) {
        // HANDLE ERRORS
        break // Stop processing further if any error occurs
      }
    }

    const existingFileIds = codeGroupDetails?.files ? codeGroupDetails.files.map(file => file['@id']) : []
    const mergedFileIds = [...existingFileIds, ...uploadedFileIds]

    if (codeGroup) {
      // Existing code group
      patchCodeGroup({
        data: {
          codeCount: values.codeCount,
          comment: values.comment,
          files: mergedFileIds,
          orderNumber: values.orderNumber,
          withTickets: values.withTickets
        },
        uid: codeGroup.uid
      }).then((codeGroup) => {
        setSubmitting(false)
        setOpen(false)
        setFilesToUpload(0)
        onSuccess(codeGroup)
      }).catch((e) => {
        const err = e as UseMutationResult<ApiReponseError>
        if (err.data?.violations) {
          const formErrors = err.data.violations.reduce((acc, violation) => {
            acc[violation.propertyPath] = t(`form.${violation.message}`, { defaultValue: violation.message })

            return acc
          }, {})

          setErrors(formErrors)
        }
        captureException(e)
      })
    } else {
      // New code group
      createCodeGroup({
        codeCount: values.codeCount,
        comment: values.comment,
        experience: experience['@id'],
        files: mergedFileIds,
        orderNumber: values.orderNumber,
        withTickets: values.withTickets
      }).then((codeGroup) => {
        setSubmitting(false)
        setOpen(false)
        setFilesToUpload(0)
        onSuccess(codeGroup)
      }).catch((e) => {
        const err = e as UseMutationResult<ApiReponseError>
        if (err.data?.violations) {
          const formErrors = err.data.violations.reduce((acc, violation) => {
            acc[violation.propertyPath] = t(`form.${violation.message}`, { defaultValue: violation.message })

            return acc
          }, {})

          setErrors(formErrors)
        }
        captureException(e)
      })
    }
  }

  const onFileDeleteClick = async (fileToDelete: CodeGroupFile) => {
    if (codeGroup && codeGroupDetails?.files) {
      const updatedFiles = codeGroupDetails.files.filter(file => file['@id'] !== fileToDelete['@id'])

      await patchCodeGroup({
        data: {
          files: updatedFiles.map(file => file['@id'])
        },
        uid: codeGroup.uid
      })

      refetch().catch(captureException)
    }
  }

  const FormSchema = Yup.object({
    orderNumber: Yup.string().required('Required')
  })

  return (
    <Modal center open={open} setOpen={setOpen} size='large' title={codeGroup?.uid ? 'Edit booking' : 'Create booking'}>
      <div className='flex flex-col items-center'>
        <Formik<CodeBulkFormValues>
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={FormSchema}
        >
          {({ isSubmitting, isValid, setFieldValue, values }) => {
            return (
              <Form className='w-full'>
                <div className='space-y-4'>
                  <FormPlusMinusField disabledMinus={codeGroupDetails ? values.codeCount === codeGroupDetails?.codeCount : values.codeCount === 1} label={'Codes'} name={'codeCount'} />

                  <FormTextField
                    description={'Used by your customers to unlock the audioguide'}
                    label={'Order Number'}
                    name={'orderNumber'}
                    onChange={(e) => {
                      const { value } = e.target
                      setFieldValue('orderNumber', value.toUpperCase()).catch(captureException)
                    }}
                    required
                  />

                  <FormTextField
                    description={'For internal use only. Not visible to customers.'}
                    label={'Comment'}
                    name={'comment'}
                  />
                </div>

                <div className='mt-8 flex w-full flex-col'>
                  <FormCheckField name='withTickets'>
                    I want to upload tickets with the codes :
                  </FormCheckField>
                </div>

                {values.withTickets && (
                  <div className={classNames({
                    'mt-2': codeGroupDetails,
                    'mt-4': !codeGroupDetails
                  })}
                  >
                    <div className='text-sm font-bold'>
                      Tickets
                    </div>

                    <p className='mt-2 text-sm'>Drag and drop your tickets here</p>

                    {(codeGroupDetails?.files?.length || values.files.length > 0) && (

                      <ul className='my-4 space-y-1 border-y border-gray-200 py-3'>
                        {codeGroupDetails?.files?.map((file) => (
                          <li className='flex space-x-4'>
                            <a className='flex items-center justify-between space-x-2 rounded-lg px-2 hover:bg-gray-50'
                              href={file.url} target='_blank'
                            >
                              <span className='flex items-center space-x-2'>
                                <DocumentIcon className='size-4' />

                                <span className='max-w-80 overflow-hidden text-ellipsis'>{file.originalName}</span>
                              </span>
                            </a>

                            <button
                              className='rounded-lg border border-gray-300 bg-white p-1 text-black hover:bg-black hover:text-white'
                              onClick={() => {
                                onFileDeleteClick(file).catch(captureException)
                              }}
                              type='button'
                            >
                              <TrashIcon className='size-3' />
                            </button>

                          </li>
                        ))}

                        {/* List the files that are about to be uploaded */}

                        {values.files?.map((file, index) => (
                          <li key={index}>
                            <div className='flex items-center justify-between space-x-2 rounded-lg bg-gray-50 px-2'>
                              <span className='flex items-center space-x-2'>
                                <DocumentIcon className='size-4' />

                                <span>{file.name}</span>
                              </span>
                            </div>
                          </li>
                        ))}

                        {isSubmitting && Array.from({ length: filesToUpload }).map((_, index) => (
                          <li key={index}>
                            <div className='flex items-center justify-between space-x-2 rounded-lg bg-gray-50 px-2'>
                              <span className='flex items-center space-x-2'>
                                <span className='h-5 w-full bg-gray-50' />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>

                    )}

                    <BookingFilesField
                      accept={'.pdf,.jpg,.jpeg,.png,.gif'}
                      autoSubmit={false}
                      hasFiles={!!codeGroupDetails?.files?.length}
                      isSubmitting={isSubmitting}
                      name={'files'}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}

                <div className='mt-5 flex flex-col space-y-2'>
                  <Button
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    type='submit'
                  >
                    {codeGroup ? 'Update' : 'Create'}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default CodeBulkGroupModal
