import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type CodeBulkExperience } from '@interfaces/api/code-bulk-experiences'
import useAxios from '@services/api/axios'

const useGetCodeBulkExperience = (uid: string): UseQueryResult<CodeBulkExperience, ApiError> => {
  const { get } = useAxios()

  return useQuery<CodeBulkExperience, ApiError>({
    enabled: !!uid,
    queryFn: async () =>
      await get<undefined, CodeBulkExperience>(`api/partner-realm/code-bulk-experiences/${uid}`),
    queryKey: ['code-bulk-experience', uid],
    retry: 5
  })
}

export default useGetCodeBulkExperience
