import { ErrorMessage, Field, useField } from 'formik'

import { type FormTextFieldProps } from '@components/form-text-field/form-text-field.interfaces'

const FormTextField = ({ description, label, name, onChange, placeholder, required = false, type = 'text' }: FormTextFieldProps) => {
  const [field] = useField(name)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    } else {
      field.onChange(event)
    }
  }

  return (
    <div className='flex flex-col'>
      <label className='text-sm font-bold' htmlFor={name}>
        {label}
      </label>

      <Field
        aria-describedby={`${name}-description`}
        className='mt-2 block w-full rounded-lg border px-3 py-2.5 text-sm'
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        type={type}
      />

      {description && (
        <p className='mt-2 text-sm text-gray-500' id={`${name}-description`}>
          {description}
        </p>
      )}

      <ErrorMessage className='mt-2 text-xs font-bold text-primary' component='div' name={name} />
    </div>
  )
}

export default FormTextField
