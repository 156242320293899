import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api'
import { type CodeGroup } from '@interfaces/api/code-group'
import useAxios from '@services/api/axios'

const useGetCodeGroup = (codeGroupUid: string, open: boolean): UseQueryResult<CodeGroup, ApiError> => {
  const { get } = useAxios()

  return useQuery<CodeGroup, ApiError>({
    enabled: !!codeGroupUid && open,
    queryFn: async () =>
      await get<undefined, CodeGroup>(`api/partner-realm/code-groups/${codeGroupUid}`),
    queryKey: ['codeGroup', codeGroupUid],
    retry: 5,
    staleTime: 0
  })
}

export default useGetCodeGroup
