import { useMutation } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

export interface CreateCodeGroupParameters {
  codeCount: number
  comment: string
  experience: string
  files: string[]
  orderNumber: string
  withTickets: boolean
}
const useCreateCodeGroup = () => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (data: CreateCodeGroupParameters) => {
      return await post<never, never>('api/partner-realm/code-groups', data as never)
    }
  })
}

export default useCreateCodeGroup
