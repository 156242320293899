import Modal from '@components/modal/modal'
import { type CodeBulkExportsInterfaces } from '@pages/codes/code-bulk-details/code-bulk-exports'
import { CodeExportForm } from '@pages/codes/code-bulk-details/code-bulk-exports/components/code-export-form'
import { CodeExportList } from '@pages/codes/code-bulk-details/code-bulk-exports/components/code-export-list'

const CodeBulkExports = ({ codeBulkExperience, open, setOpen }: CodeBulkExportsInterfaces) => {
  return (
    <Modal center open={open} setOpen={setOpen} size='large' title={`CSV Export - ${codeBulkExperience?.experience.name}`}>
      <div className={'text-center'}>
        <p className='my-2 text-xs font-bold uppercase text-red-500'>{`${codeBulkExperience?.remainingUnsentCount} codes available`}</p>
      </div>

      <CodeExportForm codeBulkExperience={codeBulkExperience} />

      <CodeExportList experience={codeBulkExperience?.experience} />
    </Modal>
  )
}

export default CodeBulkExports
