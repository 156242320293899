import React from 'react'

import Modal from '@components/modal/modal'
import { type ModalDeleteConfirmProps } from '@components/modal-delete-confirm/modal-delete-confirm.interfaces'

const ModalDeleteConfirm = ({ cancelButtonText = 'Cancel', confirmButtonText = 'Remove', onConfirm, open, setOpen, text, title }: ModalDeleteConfirmProps) => {
  return (
    <Modal center open={open} setOpen={setOpen} title={title}>
      <div className='flex flex-col items-center p-8'>
        <div className='text-center'>{text}</div>

        <div className='mt-6 flex gap-8'>
          <button
            className='flex w-full justify-center rounded-full border-0 bg-black px-5 py-2 uppercase text-white shadow-sm sm:text-sm sm:leading-6'
            onClick={onConfirm}
          >
            {cancelButtonText}
          </button>

          <button
            className='flex w-full justify-center rounded-full border-0 bg-primary px-5 py-2 uppercase text-white shadow-sm sm:text-sm sm:leading-6'
            onClick={onConfirm}
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteConfirm
