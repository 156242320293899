import { useEffect, useState } from 'react'

import Codes from '@pages/codes/codes'
import { Bookings, OnboardingTracker } from '@root/pages'
import { useMe } from '@services/api/auth/use-me'
import { useFeatures } from '@services/features'

const Home = () => {
  const { hasBookingFeature, hasCodeBulkFeature, hasPartnerCodeBulkFeature, isReady } = useFeatures()
  const [finishedOnboarding, setFinishedOnboarding] = useState(false)
  const [loadingFinished, setLoadingFinished] = useState(false)
  const { data: me } = useMe()

  useEffect(() => {
    if (me) {
      const isFormFilled =
        me?.companyName !== null &&
        me?.address?.addressLine1 !== null &&
        me?.address?.postalCode !== null &&
        me?.address?.city !== null

      setFinishedOnboarding(isFormFilled)
      setLoadingFinished(true)
    }
  }, [me])

  if (!isReady || !loadingFinished) {
    <div>Loading</div>
  }

  if (!finishedOnboarding || !me?.verified || !me.validated) {
    return <OnboardingTracker finishedOnboarding={finishedOnboarding} />
  }

  if (hasBookingFeature && finishedOnboarding && me?.verified && me?.validated) {
    return <Bookings />
  }

  if (!hasBookingFeature && (hasCodeBulkFeature || hasPartnerCodeBulkFeature)) {
    return <Codes />
  }

  return (
    <div>
      {'You don\'t have enough rights to access '}
    </div>
  )
}

export default Home
