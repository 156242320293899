import { useMutation, type UseQueryOptions } from '@tanstack/react-query'

import useAxios from '@services/api/axios'

export interface CodeGroupPatch {
  codeCount?: number
  codes?: string[]
  comment?: string
  files?: string[]
  orderNumber?: string
  withTickets?: boolean
}
interface UpdateCodeGroupParameters extends Omit<UseQueryOptions, 'queryKey'> {
  data: CodeGroupPatch
  uid: string
}
const usePatchCodeGroup = () => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, uid }: UpdateCodeGroupParameters) => {
      return await patch<never, never>(
        `api/partner-realm/code-groups/${uid}`,
        data as never
      )
    }
  })
}

export default usePatchCodeGroup
