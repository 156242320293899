import React from 'react'
import { Link } from 'react-router-dom'

import { type CodeBulkExperience } from '@interfaces/api/code-bulk-experiences'

interface CodeBulksExperiencesListEntryInterfaces {
  codeBulkExperiences: CodeBulkExperience
}
const CodeBulksExperiencesListEntry = ({ codeBulkExperiences }: CodeBulksExperiencesListEntryInterfaces) => {
  return (
    <Link className='group flex cursor-pointer items-center justify-between rounded-full border border-gray-50 bg-white py-1 text-sm hover:bg-gray-30' key={codeBulkExperiences.uid} to={`/codes/${codeBulkExperiences.uid}`}>

      <div className='flex w-1/2 items-center space-x-5'>
        <div className='px-3 py-2'>
          {codeBulkExperiences.experience.cover
            ? (
              <img
                alt={codeBulkExperiences.experience.name}
                className='size-14 rounded-full object-cover transition-opacity duration-200 ease-in-out group-hover:opacity-70'
                src={codeBulkExperiences.experience.cover.url}
              />
            )
            : (
              <div
                className='size-14 rounded-full bg-gray-200 transition-opacity duration-200 ease-in-out hover:opacity-70'
              />
            )}
        </div>

        <div className='px-3 py-2'>
          <div className='flex flex-col'>
            <span>{codeBulkExperiences.experience.name}</span>

            <span className='text-primary'>{codeBulkExperiences.experience?.city?.name}</span>
          </div>
        </div>
      </div>

      <div className='flex w-1/2 items-center justify-between pr-4'>
        <div className='space-x-1 px-3 py-2'>
          <span>Sent</span>

          <span className='font-bold'>
            x
            {codeBulkExperiences.totalCount - codeBulkExperiences.remainingUnsentCount}
          </span>
        </div>

        <div className='space-x-1 px-3 py-2'>
          <span>Available</span>

          <span className='font-bold text-primary'>
            x
            {codeBulkExperiences.remainingUnsentCount}
          </span>
        </div>

        <div className='px-3 py-2' />
      </div>
    </Link>
  )
}

export default CodeBulksExperiencesListEntry
