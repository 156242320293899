import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import ButtonLoader from '@components/button-loader/button-loader'
import FormPhoneField from '@components/form-phone-field'
import FormSelectField from '@components/form-select-field'
import { useMe } from '@services/api/auth/use-me'
import usePatchMe from '@services/api/auth/use-patch-me'
import { captureException } from '@services/exceptions/capture-exception'
import countriesJson from '@services/translations/fr/countries.json'

const countriesOptions = Object.entries(countriesJson).map(([key, name]) => ({
  key,
  name
}))

interface LegalFormProps {
  afterSubmit: () => void
  editMode?: boolean
}
const LegalForm = ({ afterSubmit, editMode = false }: LegalFormProps) => {
  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/
  const websiteRegExp = /^https:\/\/.+/

  const FormSchema = Yup.object({
    addressLine1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    companyName: Yup.string().required('Required'),
    companyRegistrationNumber: Yup.string(),
    country: Yup.string().required('Required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Invalid phone number').required('Required'),
    postalCode: Yup.string().required('Required'),
    prefixCode: Yup.string()
      .required('Required')
      .min(2, 'Prefix code must be at least 2 characters')
      .max(4, 'Prefix code must be at most 4 characters')
      .matches(/^[a-zA-Z0-9]+$/, 'Prefix code can only contain letters or numbers'),
    taxNumber: Yup.string(),
    website: Yup.string()
      .matches(websiteRegExp, 'Invalid website url. Please use https://')
      .optional()
  })

  const { data: me } = useMe()
  const { mutateAsync: patchMe } = usePatchMe()

  const [errorMsg, setErrorMsg] = useState('')
  const [initialValues, setInitialValues] = useState({
    addressLine1: '',
    city: '',
    companyName: '',
    companyRegistrationNumber: '',
    country: '',
    phoneNumber: '',
    postalCode: '',
    prefixCode: '',
    taxNumber: '',
    website: ''
  })

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      addressLine1: me?.address?.addressLine1 ?? '',
      city: me?.address?.city ?? '',
      companyName: me?.companyName ?? '',
      companyRegistrationNumber: me?.companyRegistrationNumber ?? '',
      country: me?.address?.country ?? 'FR',
      phoneNumber: me?.address.phoneNumber ?? '',
      postalCode: me?.address.postalCode ?? '',
      prefixCode: me?.prefixCode ?? '',
      taxNumber: me?.taxNumber ?? ''
    })
  }, [me])

  const onSubmit = async (
    {
      addressLine1,
      city,
      companyName,
      companyRegistrationNumber,
      country,
      phoneNumber,
      postalCode,
      prefixCode,
      taxNumber,
      website
    },
    { setSubmitting }
  ) => {
    setSubmitting(true)

    try {
      await patchMe({
        address: {
          addressLine1,
          city,
          country,
          phoneNumber,
          postalCode
        },
        companyName,
        companyRegistrationNumber,
        prefixCode,
        taxNumber,
        website
      })
      setSubmitting(false)

      afterSubmit()
    } catch (err) {
      captureException(err as Error)

      setErrorMsg('An error occured')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={FormSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form className='space-y-6'>
            <div className='flex flex-col'>
              <label className='text-sm font-bold' htmlFor='companyName'>
                Company name
              </label>

              <Field
                autoComplete='companyName'
                className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                name='companyName'
                placeholder='Sky Travel'
                required
              />

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='companyName'
              />
            </div>

            <div className='flex flex-col'>
              <label className='text-sm font-bold' htmlFor='addressLine1'>
                Address
                <sup>*</sup>
              </label>

              <Field
                autoComplete='addressLine1'
                className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                name='addressLine1'
                placeholder='123 Example Street'
                required
                type='text'
              />

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='addressLine1'
              />
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label className='text-sm font-bold' htmlFor='postalCode'>
                  Postal Code
                  <sup>*</sup>
                </label>

                <Field
                  autoComplete={'postal-code'}
                  className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                  name={'postalCode'}
                  placeholder={'75000'}
                  required
                  type='text'
                />

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='postalCode'
                />
              </div>

              <div className='flex flex-col'>
                <label className='text-sm font-bold' htmlFor='city'>
                  City
                  <sup>*</sup>
                </label>

                <Field
                  autoComplete='city'
                  className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                  name='city'
                  placeholder='Paris'
                  required
                  type='text'
                />

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='city'
                />
              </div>
            </div>

            <div className='flex flex-col'>
              <FormSelectField
                defaultValue={'FR'}
                key={'country'}
                label='Country'
                name={'country'}
                options={countriesOptions}
                required
              />
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label className='text-sm font-bold' htmlFor='companyRegistrationNumber'>
                  RCS Number
                </label>

                <Field
                  className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                  name={'companyRegistrationNumber'}
                  placeholder={'12345'}
                  type='text'
                />

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='companyRegistrationNumber'
                />
              </div>

              <div className='flex flex-col'>
                <label className='text-sm font-bold' htmlFor='taxNumber'>
                  Tax number
                </label>

                <Field
                  autoComplete='taxNumber'
                  className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                  name='taxNumber'
                  placeholder='FRXXXX'
                  type='text'
                />

                <ErrorMessage
                  className='mt-2 text-xs font-bold text-primary'
                  component='div'
                  name='taxNumber'
                />
              </div>
            </div>

            <div className='flex flex-col'>
              <FormPhoneField label={'Phone number'} name={'phoneNumber'} required />
            </div>

            <div className='flex flex-col'>
              <label className='text-sm font-bold' htmlFor='website'>
                Website
              </label>

              <Field
                className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                name='website'
                placeholder='https://'
              />

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='website'
              />
            </div>

            <div className='flex flex-col'>
              <label className='text-sm font-bold' htmlFor='companyName'>
                Order number prefix
                <sup>*</sup>
              </label>

              <p className='mt-2 text-sm text-gray-500'>
                This prefix will be used to generate unique order numbers
              </p>

              <Field
                className='mt-2 block w-full appearance-none rounded-lg border border-gray-200 px-3 py-2.5 text-sm placeholder:text-gray-400 focus:border-primary focus:outline-none'
                maxLength={4}
                minLength={2}
                name='prefixCode'
                placeholder='SKT'
                required
              />

              <ErrorMessage
                className='mt-2 text-xs font-bold text-primary'
                component='div'
                name='prefixCode'
              />
            </div>

            <div className='text-xs font-medium text-red-500'>{errorMsg}</div>

            <button
              className='flex w-full justify-center rounded-full border-0 bg-primary py-2 text-white shadow-sm sm:text-sm sm:leading-6'
              disabled={isSubmitting}
              type='submit'
            >
              {isSubmitting
                ? <ButtonLoader />
                : (
                  <>
                    {editMode ? 'SAVE' : 'CONTINUE'}
                  </>
                )}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LegalForm
